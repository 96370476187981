import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import useApi from "../../api/useApi";
import { useStore } from "../../store/store";

const usePreSale = () => {
  const [referralData, setRefferalData] = useState({});
  const [saleData, setSaleData] = useState({});
  const [userData, setUserData] = useState({});
  const { getReferralData, getIDOData } = useApi();

  const getUserReferralData = useQuery({
    queryKey: ["getReferralData"],
    queryFn: () =>
      getReferralData(useStore.getState().userid)
        .then((res) => {
          setRefferalData(res);
          return res;
        })
        .catch((error) => {
          console.log(error);
        }),
  });

  const getSaleData = useQuery({
    queryKey: ["getIDOData"],
    queryFn: () =>
      getIDOData()
        .then((res) => {
          setSaleData(res[0]);
        })
        .catch((error) => {
          console.log(error);
        }),
  });

  const getUserData = () => {};

  return { saleData, referralData, getUserReferralData, getSaleData, userData };
};

export { usePreSale };
