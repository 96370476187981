import { useState, useEffect, useRef } from "react";
import Header from "../fixed-header/Header";
import { useHome } from "./useHome";
import { useStore } from "../../store/store";
import useApi from "../../api/useApi";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import "../../App.css"; // Ensure your CSS file with the styles is imported
import WebApp from "@twa-dev/sdk"; // Ensure WebApp is correctly imported
import playIcon from "../../assets/play_task.png";

const Home = ({ mutateData }) => {
  const { taskData, userTaskData, isLoading, isError, createEntry } = useHome();
  const { startTask } = useApi();
  const [isDataReady, setIsDataReady] = useState(false);
  const pointsRef = useRef(null);
  const userName = useRef();
  const prevPointsRef = useRef(useStore.getState().points);
  const navigate = useNavigate();
  const [allowPresale, setAllowPresale] = useState(false);
  const [enterUserNamePopup, setEnterUserNamePopup] = useState({});
  const [error, setError] = useState(false);

  const { mutate } = useMutation({
    mutationFn: startTask,
    retry: 4,
    retryDelay: 1000,
    onSuccess: (data, variables) => {
      const { item } = variables;
      console.log(data);
      if (data.link) {
        WebApp.openLink(data.link); // Open link in the app
        data.completed = false; // Task started but not completed
      } else if (!data.link && !item.completed) {
        data.completed = true; // Task completed
        mutateData(); // Refetch task data
        userTaskData.refetch(); // Refetch user task data after task completion
      }
      userTaskData.refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // Effect to handle data readiness and trigger refetch on component mount
  useEffect(() => {
    if (!isLoading && !isError && taskData) {
      setIsDataReady(true);
    }

    userTaskData.refetch(); // Refetch task data on mount
  }, [isLoading, isError, taskData, userTaskData]);

  // useEffect(() => {
  //   userTaskData.refetch();
  // }, [useStore.getState().userid]);

  // useEffect(() => {
  //   Object.keys(taskData).length > 0 && setIsDataReady(true);
  // }, [taskData]);

  const points = useStore((state) => state.points);
  const formattedPoints = points ? points.toLocaleString("en-US") : "0";

  // Effect to trigger point animation when points increase
  useEffect(() => {
    if (points > prevPointsRef.current) {
      if (pointsRef.current) {
        pointsRef.current.classList.add("shrink-jump");
        pointsRef.current.addEventListener("animationend", () => {
          pointsRef.current.classList.remove("shrink-jump");
        });
      }
    }
    prevPointsRef.current = points;
  }, [points]);

  const HomeComponent = (
    <div className="ho1">
      <div>
        <span ref={pointsRef} className="ho2">
          {formattedPoints}
        </span>
        <span className="ho2"> XRRt</span>
      </div>

      <button
        className={`ho3 glow-button ${!allowPresale && "cursor-not-allowed"}`}
        onClick={() => allowPresale && navigate("/presale")}
      >
        {allowPresale ? "Pre-sale Round 2" : "Game coming soon on Android"}
      </button>

      <marquee>
        <span className="ho4">
          Social media followers will be audited. Verified tasks will only be
          rewarded.
        </span>
      </marquee>
    </div>
  );

  const sections = [
    { name: "Task", function: taskData?.non_included || [] },
    { name: "", function: taskData?.ongoingTask || [] },
  ];

  const handleTaskAction = (item, status) => {
    if (item.name.toLowerCase().includes("twitter") && status == 0) {
      setEnterUserNamePopup(item);
    } else {
      mutate({ task: item.id, user: useStore.getState().userid, item }); // Pass item as part of the mutation variables
    }
    userTaskData.refetch();
  };

  const enterSocialMediaUi = () => (
    <div className=" ho5">
      <p className="ho6">Enter you Twitter username</p>
      <input
        type="text"
        className="ho7"
        ref={userName}
        onChange={() => setError(false)}
      />
      {error && <span className="text-red-500">Invalid Username</span>}
      <button className="ho8" onClick={validate}>
        Submit
      </button>

      <span
        className="ho9"
        onClick={() => {
          setEnterUserNamePopup("");
        }}
      >
        X
      </span>
    </div>
  );

  const validate = async () => {
    const data = {
      taskName: enterUserNamePopup.name,
      username: userName.current.value,
      telegramId: useStore.getState().userid,
    };

    if (userName.current.value.length == 0) {
      setError(true);
    } else {
      mutate({
        task: enterUserNamePopup.id,
        user: useStore.getState().userid,
      }); // Pass item as part of the mutation variables
      createEntry.mutate(data);
      setEnterUserNamePopup(false);
    }
    userTaskData.refetch();
    setEnterUserNamePopup(false);
  };

  return (
    <div>
      <div className="ho10">
        <Header heading={"Airdrop Campaign"} element={HomeComponent} />
      </div>

      <div className="ho11">
        {!isDataReady ? (
          <div className="ho12">Loading tasks...</div>
        ) : (
          sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <h1 className="ho13">{section.name}</h1>
              {section.name === "Task" && (
                <div className="ho19">
                  {/*<div className="ho20">
                    <img src={playIcon} alt="task icon" className="ho14" />
                    <div className="text-white">
                      <div className="ho15">Play and Collect More Tokens</div>
                      <div className="ho16">Earn 45 XRRt per match</div>
                    </div>
                  </div>
                  <button className="ho17" onClick={() => navigate("/game")}>
                    Play
                  </button>*/}
                </div>
              )}

              {section.function.map((item) => (
                <div key={item.id} className="ho18">
                  <div className="flex">
                    <img src={item.icon} alt="task icon" className="ho20" />
                    <div>
                      <div className="ho21">{item.name}</div>
                      <div className="ho22">+{item.points} XRRt</div>
                    </div>
                  </div>
                  {section.name === "Task" ? (
                    <button
                      className="ho23"
                      onClick={() => handleTaskAction(item, 0)}
                    >
                      {item.completed ? "Claim" : "Start"}
                    </button>
                  ) : (
                    <button
                      className="ho24"
                      onClick={() => handleTaskAction(item, 1)}
                    >
                      Claim
                    </button>
                  )}
                </div>
              ))}
            </div>
          ))
        )}

        <div className="ho25">
          <h1 className="ho26">Points Earned</h1>
          {taskData?.completedTask?.length === 0 && (
            <div className="ho27">No Task Completed Yet</div>
          )}
          {taskData?.completedTask?.map((item) => (
            <div key={item.id} className="ho28">
              <div className="ho29">
                <img src={item.icon} alt="task icon" className="ho30" />
                <div>
                  <div className="ho15">{item.name}</div>
                </div>
              </div>
              <div className="ho31">+{item.points} XRRt</div>
            </div>
          ))}
        </div>
      </div>
      {Object.keys(enterUserNamePopup).length > 1 && enterSocialMediaUi()}
    </div>
  );
};

export default Home;
