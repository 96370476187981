import { useState } from "react";
import Header from "../fixed-header/Header";
import { useStore } from "../../store/store";
import { useGather } from "./useGather";

const Gather = () => {
  const [score, setScore] = useState(0);
  const [isBtnMenuOpen, setIsBtnMenuOpen] = useState(false);

  const { inviteData } = useGather();

  const GatherComponent = (
    <>
      <div className="ga1">
        <div>Invited</div>
        <div>{inviteData.length}</div>
      </div>

      <div className="ga2 " onClick={() => setIsBtnMenuOpen(true)}>
        Gather Friends
      </div>
    </>
  );

  const shareLink =
    "https://t.me/RapidRushApp_bot/Rapid_Rush?startapp=" +
    useStore.getState().userid;

  return (
    <div>
      <div className="ga3">
        <Header heading={"Gather Friends"} element={GatherComponent} />
      </div>
      <div className="ga4">
        {inviteData.map((entry) => {
          return (
            <div>
              <div className="ga5">
                <div className="ga6">
                  <span className="ga7">
                    {entry.toUser.slice(0, 2).toUpperCase()}
                  </span>
                  <span className="ga8">{entry.toUser}</span>
                </div>
                <div className="ga9">+50 XRRt</div>
              </div>
            </div>
          );
        })}
      </div>

      {isBtnMenuOpen && (
        <div className="ga10">
          <div className="ga11">
            Gather Friends
            <div className="ga12" onClick={() => setIsBtnMenuOpen(false)}>
              X
            </div>
          </div>
          <div className="ga13">
            <button
              className="ga14"
              onClick={() => navigator.clipboard.writeText(shareLink)}
            >
              Copy Invite Link
            </button>
            <button
              className="ga14"
              onClick={() =>
                window.open(
                  `https://t.me/share/url?url=${shareLink}&text=Join%20the%20race%20with%20parkour%20move`,
                  "_self"
                )
              }
            >
              Share Invite Link
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gather;
