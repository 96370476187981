import { useState, useMemo, useEffect, useRef } from "react";

import Countdown from "./Countdown.jsx";
import { usePreSale } from "./usePreSale.js";

const Ido = () => {
  const ref = useRef();

  const [IdoData, setIdoData] = useState({});
  const { saleData, userData, referralData } = usePreSale();
  const [isStarted, setIsStarted] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [timerDuration, setTimerDuration] = useState(0);

  useEffect(() => {
    if (saleData != undefined) {
      if (Date.now() / 1000 < saleData["startdate"]) {
        setIsStarted(false);
        setTimerDuration(saleData["startdate"] * 1000);
      } else {
        setIsStarted(true);
        setTimerDuration(saleData["enddate"] * 1000);
        if (Date.now() / 1000 > saleData["enddate"]) {
          setIsEnded(true);
          setTimerDuration(0);
        }
      }
    }

    setIdoData(saleData);
  }, [saleData]);

  const tokenDetails = [
    { name: "Total Supply:", value: IdoData ? IdoData["totalTokens"] : 0 },
    {
      name: "Sale Allocation:",
      value: IdoData ? IdoData["remainingToken"] + " XRR(Available)" : 0,
    },
    {
      name: "Token Price:",
      value: IdoData ? "$" + IdoData["tokenPrice"] : 0,
    },
  ];

  const referralDetails = [
    {
      name: "Invited:",
      value: referralData ? referralData["totalInvites"] : 0,
    },
    {
      name: "Bonus:",
      value: referralData ? referralData["bonusPercent"] : 0,
    },
    {
      name: "Purchased Invitees:",
      value: referralData ? +referralData["purchaseCount"] : 0,
    },
    {
      name: "Your Bonus Reward:",
      value: referralData ? "$" + referralData["bonusAmount"] : 0,
    },
  ];

  // const getTokenData = async () => {
  //   let res = await BasicTokenDetails();
  //   res["totalSupply"] = Math.ceil(Number(res["totalSupply"]) / 1e18);
  //   res["saleAllocation"] = Math.ceil(Number(res["saleAllocation"]) / 1e18);
  //   res["tokenPrice"] = 1 / Number(res["tokenPrice"]);
  //   setTokenInfo(res);
  // };

  // const getXrrBalance = async () => {
  //   let res = await tokenBalance();
  //   setTokenPurchased(Number(res["availableBalance"]) / 1e18);
  // };

  // useEffect(() => {
  //   getTokenData();
  //   getXrrBalance();
  // }, []);

  const handleBuy = async () => {
    const amount = ref.current.value / 80;
    // await getUsdt(amount * 1e18);
  };

  const handleWithdraw = async () => {
    console.log("handleWithdraw working");
  };

  const date = timerDuration;

  const IdoComponent = () => {
    return (
      <div className="p12">
        {/* <h2 className="font-medium text-4xl text-center">
          {isStarted ? "Token Sale Ends in" : "Token Sale Starts in"}
        </h2> */}
        <Countdown date={date} />
        <div className="text-white">
          <h2 className="p13">Token details</h2>
          <div className="p14">
            <table className="p16">
              {tokenDetails.map((detail) => {
                return (
                  <tr className="border-spacing-1">
                    <td className="p15">{detail.name}</td>
                    <td className="p15">{detail.value}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="p17">
          <div>
            <span className="p18">You Get: 50000 XRR</span>
            <input
              type="number"
              placeholder="Enter amount in USDT"
              className="p11"
              ref={ref}
            />
          </div>
          <button className={`p11 p19`} disabled={false} onClick={handleBuy}>
            Buy $XRR
          </button>
          <div className="text-sm  font-bold text-white">
            Token Purchased:
            {userData.data != undefined
              ? userData.data.length != 0
                ? userData.data[0].xrrToken
                : 0
              : 0}
          </div>
        </div>
        <div className="text-white">
          <h2 className="p20">Referral Bonus</h2>
          <div className="p21">
            <table className="w-full">
              {referralDetails.map((detail) => {
                return (
                  <tr className="p22">
                    <td className="p23">{detail.name}</td>
                    <td className="text-xl font-bold text-end">
                      {detail.value}
                    </td>
                  </tr>
                );
              })}
            </table>
            <div className="m-auto">
              <button
                className={`p11 p24`}
                disabled={false}
                onClick={handleWithdraw}
              >
                Withdraw Referral Bonus
              </button>
            </div>
          </div>
        </div>
        /////////////////////////////sold out
        banner///////////////////////////////
        {/* {(IdoData["remainingToken"] <= 0 || isEnded) && (
          <span>
            <img
              src="../../img/soldout-banner.png"
              alt=""
              className="absolute top-[30%] -rotate-[16deg] h-[250px] w-[610px]"
            />
          </span>
        )} */}
      </div>
    );
  };

  return <IdoComponent />;
};

export default Ido;
