import { useRef } from "react";
import WebApp from "@twa-dev/sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../api/useApi";
import { useStore } from "../../store/store";
import { useNavigate } from "react-router-dom";

const Game = ({ mutateData }) => {
  const iframeRef = useRef(null);
  const { updateGameTask } = useApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient(); // Import the query client

  const { mutate } = useMutation({
    mutationKey: ["updateGameTask"],
    mutationFn: updateGameTask,
    onSuccess: (data) => {
      console.log("Game task updated successfully:", data);
      mutateData();
      // Invalidate the 'getTaskData' query to trigger refetch
      queryClient.invalidateQueries(["getTaskData"]);
    },
    onError: (error) => {
      console.log("Error updating game task:", error);
    },
    // Added onSettled callback to ensure query invalidation
    onSettled: () => {
      queryClient.invalidateQueries(["getTaskData"]); // Ensure refetch occurs
    },
  });

  const getIframePoints = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const gameFinished = iframe.contentWindow.handleMatchEnd;
      const goBack = iframe.contentWindow.handleGoBack;

      if (typeof gameFinished === "function") {
        iframe.contentWindow.handleMatchEnd = () => {
          const totalScore = gameFinished();
          const data = {
            userid: useStore.getState().userid,
            totalScore,
          };
          mutate(data);
        };
      } else {
        console.error("handleMatchEnd function is not defined in the iframe");
      }

      if (typeof goBack === "function") {
        iframe.contentWindow.handleGoBack = () => navigate("/");
      } else {
        console.error("handleGoBack function is not defined in the iframe");
      }
    }
  };

  const iframeLoaded = () => {
    getIframePoints();
  };

  return (
    <div className="g1">
      <iframe
        ref={iframeRef}
        src={"/game.html"}
        title="Game"
        onLoad={iframeLoaded}
        className="g2"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Game;
