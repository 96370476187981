import { useQuery } from "@tanstack/react-query";
import useApi from "../../api/useApi";
import { useState } from "react";
import { useStore } from "../../store/store";

const useLeaderboard = () => {
  const { getLeaderBoard } = useApi();
  const [leaderboard, setleaderboard] = useState([]);

  const leaderboardData = useQuery({
    queryKey: ["getLeaderBoard"],
    queryFn: () =>
      getLeaderBoard(useStore.getState().userid)
        .then((res) => setleaderboard(res))
        .catch((error) => {
          console.log(error);
        }),
  });

  return { leaderboard, leaderboardData };
};

export { useLeaderboard };
