import homeicon from "../../assets/home_icon.png";
import leaderboardicon from "../../assets/leaderboard_icon.png";
import gathericon from "../../assets/gather_icon.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const footerMenu = [
    { name: "Home", img: homeicon, navTo: "/" },
    { name: "Leaderboard", img: leaderboardicon, navTo: "/leaderboard" },
    { name: "Gather", img: gathericon, navTo: "/gather" },
  ];

  return (
    <div className="f1">
      {footerMenu.map((item) => (
        <div
          onClick={() => {
            navigate(item.navTo);
          }}
          className="f2"
        >
          <img src={item.img} alt={item.name} className="f3" />
          <h4 className="f4">{item.name}</h4>
        </div>
      ))}
    </div>
  );
};

export default Footer;
