import headerArrow from "../../assets/frw_arrow.png";
import jumpUser from "../../assets/jump_user.png";

const Header = ({ heading, element }) => {
  return (
    <div className="h1">
      <div
        className="h2"
        style={{
          backgroundImage: `url(${headerArrow})`,
        }}
      >
        <h3 className="h3">{heading}</h3>
      </div>

      <div className="h4">
        <img src={jumpUser} alt="jump-icon" className="h5" />
      </div>

      <div>{element}</div>
    </div>
  );
};

export default Header;
