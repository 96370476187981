import { useMutation, useQuery } from "@tanstack/react-query";
import useApi from "../../api/useApi";
import { useState } from "react";
import { useStore } from "../../store/store";

const useHome = () => {
  const { getTask, createSocialMediaEntry } = useApi();
  const [taskData, setTaskData] = useState({});
  const setPoints = useStore((state) => state.setPoints);

  const userTaskData = useQuery({
    queryKey: ["getTaskData"],
    retry: 4,
    retryDelay: 1000,
    queryFn: () =>
      getTask(useStore.getState().userid)
        .then((res) => {
          setTaskData(res);

          // Update points in Zustand store
          if (res.points !== undefined) {
            console.log("Updating points in useStore:", res.points); // Added for debugging
            setPoints(res.points); // Changed from res.totalScore to res.points
          }
        })
        .catch((error) => {
          console.log(error);
        }),
    // Ensure the query refetches when invalidated
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const createEntry = useMutation({
    mutationKey: "createSocialMediaEntry",
    mutationFn: createSocialMediaEntry,
    retry: 4,
    retryDelay: 1000,
    onError: (error) => {
      console.log(error);
    },
  });

  return { taskData, userTaskData, createEntry };
};

export { useHome };
