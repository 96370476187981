import headerArrow from "../../assets/frw_arrow.png";
import jumpUser from "../../assets/jump_user.png";
import Ido from "./Ido";
import walletLogo from "../../assets/wallet.png";
import {
  useTonConnectUI,
  useTonWallet,
  SendTransactionRequest,
  TonConnectButton,
} from "@tonconnect/ui-react";
import TonConnect from "@tonconnect/sdk";

const PreSale = () => {
  const [tonConnectUi] = useTonConnectUI();
  const tonConnect = new TonConnect();
  const wallet = useTonWallet();

  return (
    <>
      <div
        className="p5"
        style={{
          backgroundImage: `url(${headerArrow})`,
        }}
      >
        <h3 className="p6">Pre-Sale</h3>
      </div>

      <div className="p7">
        {wallet ? (
          <div className="p8">
            <TonConnectButton />
          </div>
        ) : (
          <button className="p9" onClick={() => tonConnectUi.openModal()}>
            <img src={walletLogo} alt="wallet icon" />
            Connect wallet
          </button>
        )}
        <div className="mt-8 mb-3">
          <img
            src={jumpUser}
            alt="jump-icon"
            className="m-auto floating-image"
          />
        </div>
        mt-8 mb-3
        <h3 className="p10">Pre-sale Round 2</h3>
        <Ido />
      </div>
    </>
  );
};

export default PreSale;
