import { create } from "zustand";

const useStore = create((set) => ({
  userid: null,
  username: "",
  invites: 0,
  points: 0,

  setUserId: (user_userid) => {
    set({ userid: user_userid });
  },
  setUserName: (user_username) => {
    set({ username: user_username });
  },
  setInvites: (user_invites) => {
    set({ invites: user_invites });
  },
  setPoints: (user_points) => {
    set({ points: user_points });
  },
}));

export { useStore };
