import { useEffect, useRef, useState } from "react";

const Countdown = ({ date }) => {
  const countDownDate = new Date(date).getTime();
  const [countDown, setCountDown] = useState();
  const [counterTime, setCounterTime] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return getReturnValues(date == 0 ? 0 : countDown);
};

const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const countDownData = [
    { name: "days", function: days },
    { name: "hours", function: hours },
    { name: "minutes", function: minutes },
    { name: "seconds", function: seconds },
  ];

  return (
    <div className="p1">
      {countDownData.map((item) => {
        return (
          <div className="p2">
            <div className="p3">{item.function}</div>
            <div className="p4">{item.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Countdown;
