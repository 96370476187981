import Home from "./components/home/Home";
import Gather from "./components/gather/Gather";
import LeaderBoard from "./components/leaderboard/LeaderBoard";
import Streak from "./components/streak_animation/streak";
import useApi from "./api/useApi";
import preloader from "./assets/preloader.gif";

import WebApp from "@twa-dev/sdk";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useStore } from "./store/store";
import { useNavigate } from "react-router";

import "./App.css";
import PreSale from "./components/pre_sale/PreSale";
import Game from "./components/game/Game";

function App() {
  const [showPreloader, setShowPreloader] = useState(true);
  const { getOrCreateUser } = useApi();
  const navigate = useNavigate();
  const updateUserId = useStore((state) => state.setUserId);
  const updateUserName = useStore((state) => state.setUserName);
  const updateUserPoints = useStore((state) => state.setPoints);
  const updateUserInvites = useStore((state) => state.setInvites);
  const user_data = WebApp.initDataUnsafe;
  const user_data2 = WebApp.initData;

  const [usernamePopUp, setUsernamePopUp] = useState(false);

  const { mutate } = useMutation({
    mutationFn: getOrCreateUser,
    retry: 4,
    retryDelay: 1000,

    onSuccess: (data) => {
      updateUserId(data.userid);
      updateUserName(data.username);
      updateUserPoints(data.totalScore);

      if (data["showStreak"]) {
        navigate("/str", { state: { streak: data.streak } });
      }
    },

    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (WebApp.isExpanded === false) {
      WebApp.expand(); // Expands the Mini App to full screen
    }

    WebApp.setHeaderColor("#000000");

    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isIOS) {
      WebApp.onEvent("viewportChanged", (event) => {
        if (event.isStateStable) {
          document.documentElement.style.setProperty(
            "--tg-viewport-stable-height",
            `${event.viewportStableHeight}px`
          );
        }
      });
    }

    const user_data = WebApp.initDataUnsafe;

    try {
      const data = {
        // userid: 5870351809,
        // username: "asdas",
        userid: user_data.user.id,
        username: user_data.user.username,
      };

      if (data["username"] == undefined) {
        setUsernamePopUp(true);
      }

      if (user_data.start_param !== undefined) {
        data["referBy"] = user_data.start_param;
      }

      mutate(data);
    } catch {
      const data = {};
    }

    setTimeout(() => {
      setShowPreloader(false);
    }, 2500);
  }, []);

  const refreshData = () => {
    const data = {
      userid: user_data.user.id,
      username: user_data.user.username,
      // userid: 5870351809,
      // username: "asdas",
    };

    mutate(data);
  };

  return (
    <>
      {showPreloader && (
        <div className="h-screen w-screen absolute z-20 bg-black flex items-center justify-center">
          <img src={preloader} alt="preloader" />
        </div>
      )}

      <Routes>
        <Route path="/" element={<Home mutateData={() => refreshData()} />} />
        <Route path="/leaderboard" element={<LeaderBoard />} />
        <Route path="/gather" element={<Gather />} />
        <Route
          path="/game"
          element={<Game mutateData={() => refreshData()} />}
        />
        <Route path="/str" element={<Streak />} />
        <Route path="/presale" element={<PreSale />} />
      </Routes>
      {usernamePopUp && (
        <div className="absolute top-0 h-screen w-screen z-[99]">
          <div className=" max-w-[500px] w-[80vw] min-h-20 bg-[#0B0B1E] z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-gray-200 rounded-xl p-8">
            <p className="text-2xl text-red-600 italic text-center">Alert!!</p>
            <p className="text-lg text-white text-center">No Username Found</p>
            <p className="font-medium text-lg text-white mt-5 italic">
              Enter Username to Continue
            </p>
            <p className="font-black text-lg text-white mt-2">Steps:</p>
            <p className="itaic text-base text-white">
              Settings>profile>username
            </p>
            <span
              className="absolute right-4 top-4 font-black text-lg text-white cursor-pointer"
              onClick={() => {
                setUsernamePopUp(false);
                WebApp.close();
              }}
            >
              X
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
