import { useState } from "react";
import Header from "../fixed-header/Header";
import { useStore } from "../../store/store";
import { useLeaderboard } from "./useLeaderboard";

// Import badge images
import badge1 from "../../assets/1.png";
import badge2 from "../../assets/2.png";
import badge3 from "../../assets/3.png";

const LeaderBoard = () => {
  const { leaderboard } = useLeaderboard();

  console.log(process.env.REACT_APP_BASE_API_URL);

  // Format the user's points with commas
  const formattedPoints = useStore.getState().points.toLocaleString("en-US");

  const LeaderBoardComponent = (
    <>
      <div className="l1">
        {formattedPoints} <span>XRRt</span>
      </div>

      <div className="l2">
        <h2>Your Score</h2>
        <div className="l3">
          <div className="l4">
            <span className="l5">
              {useStore.getState().username.slice(0, 2).toUpperCase()}
            </span>
            <span style={{ fontSize: "1.10rem", lineHeight: "1.20rem" }}>
              {useStore.getState().username}
            </span>
          </div>
          <div className="l6">#{leaderboard.rank}</div>
        </div>
        <span className="l7">
          Filled XRRt: {leaderboard.totalScore}/100,000,000
        </span>
      </div>
      <h1 className="l8">Wall of Legends</h1>
    </>
  );

  // Function to get the badge based on rank
  const getBadge = (rank) => {
    switch (rank) {
      case 1:
        return <img src={badge1} alt="1st place badge" className="l9" />;
      case 2:
        return <img src={badge2} alt="2nd place badge" className="l9" />;
      case 3:
        return <img src={badge3} alt="3rd place badge" className="l9" />;
      default:
        return (
          <span
            className="l9"
            style={{ fontSize: "1rem", color: "white" }}
          >{`#${rank}`}</span>
        );
    }
  };

  return (
    <div>
      <div className="l10">
        <Header heading={"Leaderboard"} element={LeaderBoardComponent} />
      </div>

      <div className="l11">
        <div className="overflow-autos">
          {leaderboard.data !== undefined &&
            leaderboard.data.map((entry, index) => {
              const rank = index + 1; // Determine the current rank
              // Format each leaderboard entry's score with commas
              const formattedTotalScore =
                entry.totalScore.toLocaleString("en-US");

              return (
                <div
                  key={entry.username} // Assuming username is unique
                  className="flex justify-between text-white mb-3 items-center"
                >
                  <div className="flex">
                    <span className="l12">
                      {entry.username.slice(0, 2).toUpperCase()}
                    </span>
                    <div>
                      <div
                        style={{ fontSize: "1.10rem", lineHeight: "1.20rem" }}
                      >
                        {entry.username}
                      </div>
                      <div
                        className="l13"
                        style={{ fontSize: "0.80rem", lineHeight: "1.25rem" }}
                      >
                        {formattedTotalScore} XRRt
                      </div>
                    </div>
                  </div>

                  <div className="l14">{getBadge(rank)}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
