import { useQuery } from "@tanstack/react-query";
import useApi from "../../api/useApi";
import { useState } from "react";
import { useStore } from "../../store/store";

const useGather = () => {
  const { getInviteData } = useApi();
  const [inviteData, setInviteData] = useState([]);

  const userReferData = useQuery({
    queryKey: ["getLeaderBoard"],
    queryFn: () =>
      getInviteData(useStore.getState().userid)
        .then((res) => setInviteData(res))
        .catch((error) => {
          console.log(error);
        }),
  });

  return { inviteData, userReferData };
};

export { useGather };
