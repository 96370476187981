import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Footer from "./components/footer/Footer";
import bgImg from "./assets/bg_girl.png";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const queryClient = new QueryClient();
const manifestUrl = "https://mini.rapidrush.xyz/tonconnect-manifest.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <TonConnectUIProvider manifestUrl={manifestUrl}>
          <div className="test bgggg">
            <App />
          </div>
          <img
            src={bgImg}
            alt="bg girl"
            width="255"
            height="600"
            className="fixed right-0 -bottom-[8.5rem] -z-10"
          ></img>
          <Footer />
        </TonConnectUIProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
