const BASE_API = "https://adminbackend.rapidrush.xyz/api/v1/";
// const BASE_API = "http://127.0.0.1:8000/api/v1/";
const IDO_API =
  "https://my-backend-env.eba-9mq4nrg8.ap-south-1.elasticbeanstalk.com/api/v1/";

const useApi = () => {
  const triggerApi = async (api_url = BASE_API, endpoint, type, data) => {
    const body = {
      method: type,
      headers: {
        "content-type": "application/json",
      },
    };

    if (data) {
      body["body"] = JSON.stringify(data);
    }

    const response = await fetch(api_url + endpoint, body);

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  };

  const getOrCreateUser = (data) => {
    return triggerApi(BASE_API, "user/", "POST", data);
  };

  const getLeaderBoard = (userid) => {
    return triggerApi(BASE_API, "user/leaderboard/" + userid + "/", "GET");
  };

  const getTask = (id) => {
    return triggerApi(BASE_API, "task/" + id + "/", "GET");
  };

  const getInviteData = (userid) => {
    return triggerApi(BASE_API, "invites/get/" + userid + "/", "GET");
  };

  const getReferralData = (userid) => {
    return triggerApi(BASE_API, "invites/get-referral/" + userid + "/", "GET");
  };

  const getIDOData = (userid) => {
    return triggerApi(IDO_API, "sale/status/", "GET");
  };

  const startTask = (data) => {
    return triggerApi(BASE_API, "task/starttask/", "POST", data);
  };

  const updateGameTask = (data) => {
    return triggerApi(BASE_API, "task/updategamepoints/", "PUT", data);
  };

  const createSocialMediaEntry = (data) => {
    return triggerApi(BASE_API, "track_follower/create/", "POST", data);
  };

  return {
    getOrCreateUser,
    getLeaderBoard,
    getTask,
    getInviteData,
    startTask,
    getReferralData,
    getIDOData,
    updateGameTask,
    createSocialMediaEntry,
  };
};

export default useApi;
