import gif from "../../assets/confetti2.gif";
import { useNavigate, useLocation } from "react-router";

const Streak = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dayStreak = location.state.streak;

  return (
    <div
      className="s1"
      style={{
        backgroundImage: `url("${gif}")`,
      }}
    >
      <div className="s2">
        <p className="s3">{dayStreak}</p>
        <p className="s4">Days Streak</p>
        <p className="s5">+{dayStreak * 10} XRRt</p>
      </div>

      <div className=" s6">
        <h2 className="s7">Great job! Visit dail for continuous rewards</h2>
        <button className="s8" onClick={() => navigate("/")}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default Streak;
